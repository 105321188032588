export const config = {
    PREFIX: 'omniflix',
};

export const IPFS_URL = 'https://ipfs.omniflix.studio/ipfs';
export const IPFS_REFERENCE_PATH = 'ipfs://';

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_COUNT = 10;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;
